.login {
  padding-top: 100px;
  text-align: center;
}

.wrapper {
  min-height: 100%;
  margin-bottom: -220px;
}

.push {
  height: 220px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
}

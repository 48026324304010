.alert-info {
  background-color: $info;
  border-color: $info;
  color: white;
}

.alert-primary {
  background-color: $primary;
  border-color: $primary;
  color: white;
}

.alert-warning {
  background-color: $warning;
  border-color: $warning;
  font-weight: 600;
}

.alert-danger {
  background-color: $danger;
  border-color: $danger;
  color: white;
}

.alert-success {
  background-color: $success;
  border-color: $success;
  color: white;
}

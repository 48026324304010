.card {
  background-color: var(--body-bg);
  border-color: var(--card-border);
}

.card-header {
  background-color: var(--card-header);
  color: white;
}

.card-body {
  background-color: var(--card-bg);
}

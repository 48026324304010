.accordion {
  background-color: var(--body-bg);
  border-color: var(--card-border);
}

.accordion-item {
  border: none;
  background-color: var(--body-bg) !important;
}

.accordion-header {
  background-color: var(--body-bg) !important;
  color: var(--text) !important;
  margin-bottom: 3px;
}

.accordion-button {
  font-size: 1.2rem;
  background-color: var(--body-bg) !important;
  color: var(--text) !important;
  transition: all 0.25s;
  background-color: var(--accordion-bg) !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23377cb0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-body {
  padding-left: 0;
  padding-right: 0;
  background-color: var(--body-bg);
}

.accordion-button[aria-expanded='true'] {
  font-size: 2rem !important;
}

.accordion-button[aria-expanded='false']:hover {
  font-size: 1.3rem !important;
}

.form-control,
.form-select {
  background-color: var(--form-control-bg) !important;
  border-color: var(--form-control-border) !important;
  color: var(--body-color) !important;

  &:disabled {
    background-color: var(--form-control-disabled);
  }
}

.dropdown-menu {
  background-color: var(--dropdown-bg);
  color: var(--text);
  padding: 0;
}

.dropdown-item {
  background-color: var(--dropdown-bg);
  color: var(--text);

  &:hover {
    background-color: var(--dropdown-item-hover);
    color: var(--text);
  }
}

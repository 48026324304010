@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import './scss/alerts.scss';
@import './scss/tables.scss';
@import './scss/forms.scss';
@import './scss/modals.scss';
@import './scss/cards.scss';
@import './scss/buttons.scss';
@import './scss/accordion.scss';
@import './scss/dropdowns.scss';
@import './scss/pagination.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

:root {
  --dark: #{$dark};
  --less-dark: #{$lessDark};

  --link: #66b3ff;

  --login-bg: var(--dark);
  --login-form-bg: var(--dark);

  --body-bg: #{$dark};
  --body-color: #{$light};
  --text: #fff;

  --panel-bg: var(--less-dark);
  --panel-box-shadow: none;

  --table-border: #{lighten($dark, 10%)};
  --table-hover: #{darken($dark, 3%)};

  --form-control-bg: #{darken($dark, 10%)};
  --form-control-border: #{lighten($dark, 40%)};
  --form-control-disabled: #{lighten($dark, 10%)};

  --card-bg: #{darken($dark, 7%)};
  --card-header: #{darken($dark, 14%)};
  --card-border: #{darken($dark, 12%)};

  --dropdown-bg: #{darken($dark, 5%)};
  --dropdown-item-hover: #{darken($dark, 8%)};

  --accordion-bg: #{$lessDark};
}

[data-theme='light'] {
  --less-dark: #{lighten($lessDark, 65%)};

  --body-bg: #{$light};
  --body-color: #333333;
  --text: #000;

  --link: #0059b3;

  --login-bg: var(--primary);
  --login-form-bg: #{$light};

  --panel-bg: #fff;
  --panel-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  --table-border: #{darken($light, 10%)};
  --table-hover: #{darken($light, 5%)};

  --form-control-bg: #{darken($light, 1%)};
  --form-control-border: #{darken($light, 20%)};
  --form-control-disabled: #{darken($light, 15%)};

  --card-bg: #{darken($light, 3%)};
  --card-header: #{darken($light, 5%)};
  --card-border: #{darken($light, 20%)};

  --dropdown-bg: #{darken($light, 5%)};
  --dropdown-item-hover: #{darken($light, 8%)};

  --accordion-bg: var(--panel-bg);
}

html,
#root {
  height: 100%;
}

body {
  height: 100%;
  background-color: var(--body-bg);
  color: var(--body-color) !important;
}

body {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

a {
  color: var(--link);
}

hr {
  background: var(--text);
}

.nav {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-dark-md-transparent {
  background-color: var(--dark) !important;
}

.round-icon-button {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  color: white;

  &:hover {
    background: $dark;
    scale: 1.1;
    color: var(--form-control-border);
  }
}

@include media-breakpoint-up(lg) {
  .bg-dark-md-transparent {
    background-color: unset !important;
  }
}

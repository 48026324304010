.container {
  height: calc(100% - 104px);
}

.navbar {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: 500;
  font-size: clamp(16px, 1.25vw, 1.2rem);

  & .items {
    & > * {
      margin-left: clamp(4px, 1.25vw, 40px) !important;
      padding-bottom: 4px !important;
    }
  }
}

.containerWrapper {
  min-height: calc(100% - 72px);
  margin-bottom: -220px;
}

.containerPush {
  height: 220px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
}

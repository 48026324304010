.table {
  color: var(--body-color);

  th {
    border-top: 1px solid var(--table-border) !important;
    border-bottom: 2px solid var(--table-border) !important;
  }

  td {
    border-bottom: 1px solid var(--table-border) !important;
  }
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: var(--table-hover);
  color: var(--body-color);
}

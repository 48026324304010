.pagination {
  & .disabled {
    opacity: 0.3;
  }

  & .page-link {
    background-color: var(--table-border) !important;
    color: var(--text) !important;
    border: none;
  }
}
